<!--
 * @Author: xiaziwen
 * @Date: 2021-01-13 12:01:00
 * @LastEditors: xiaziwen
 * @LastEditTime: 2021-01-13 12:03:20
 * @FilePath: \crm-education\src\views\common\error\404.vue
-->
<template>
  <a-result status="404" title="错误路径" sub-title="对不起，页面不存在或没有权限">
    <template #extra>
      <a-button class="cus-button" type="primary" @click="onBack">返回登录页</a-button>
    </template>
  </a-result>
</template>

<script>
export default {
  name: 'error_page',
  methods: {
    onBack() {
      this.$router.push({ name: 'login_student' })
    }
  }
}
</script>
